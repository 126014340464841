<template>
  <div>
    <b-field label="Products">
      <b-taginput
        type="is-black"
        v-model="products"
        ellipsis
        size="is-small"
        icon="tag"
        placeholder="Filter by Product"
        aria-close-label="Remove this product"
        @input="updateProducts"
      >
      </b-taginput>
    </b-field>
  </div>
</template>
<script>
export default {
  name: 'Products',
  data () {
    return {
      products: []
    }
  },
  methods: {
    updateProducts () {
      this.$emit('products:updated', this.products.join(','))
    }
  }
}
</script>