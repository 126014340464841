<template>
  <div>
    <b-field label="Variants">
      <b-taginput
        type="is-black"
        v-model="variants"
        ellipsis
        size="is-small"
        icon="tag"
        placeholder="Filter by Variant"
        aria-close-label="Remove this variant"
        @input="updateVariants"
      >
      </b-taginput>
    </b-field>
  </div>
</template>
<script>
export default {
  name: 'Variants',
  data () {
    return {
      variants: []
    }
  },
  methods: {
    updateVariants () {
      this.$emit('variants:updated', this.variants.join(','))
    }
  }
}
</script>