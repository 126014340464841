<template>
  <div>
    <navbar @login="showLogin=true" @entity:updated="entityUpdated"></navbar>
    <div class="container is-fluid" v-cloak>
      <login v-if="!loading && user === null" :show-login="showLogin" @login:canceled="showLogin=false"></login>
      <div v-if="user !== null" class="columns">
        <div class="column is-multiline is-full has-text-centered is-capitalized is-size-5 has-text-weight-bold">{{((this.entity === 'rmas') ? ((this.approvedOnly) ? 'Approved ': 'All ') : '') + this.entityLabels[this.entity]}}</div>
      </div>
      <div v-if="user !== null" class="columns">
        <div class="column is-2">
          <sidebar @filters:updated="filtersUpdated" :entity="entity" :approved-only="approvedOnly"></sidebar>
        </div>
        <div class="column is-10">
          <report v-if="user !== null && !loading" :filters="filters" :entity="entity" :approved-only="approvedOnly"></report>
          <div v-else class="card">
            <div class="card-content">
              <div class="content">
                <br>
                <div class="columns">
                  <div class="column is-1">
                    <b-skeleton animated></b-skeleton>
                  </div>
                  <div class="column is-1">
                    <b-skeleton animated></b-skeleton>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-skeleton animated height="40px"></b-skeleton>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-skeleton animated height="100px"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Navbar from './Navbar.vue'
import Login from './Login.vue'
import Sidebar from './Sidebar.vue'
import Report from './Report.vue'
import axios from 'axios'
export default {
  name: 'Home',
  components: {
    Navbar,
    Login,
    Sidebar,
    Report
  },
  data () {
    return {
      entity: 'orders',
      showLogin: true,
      filters: {},
      entityLabels: {
        'orders': 'Orders',
        'rmas': 'Returns',
        'cancels': 'Cancels'
      },
      approvedOnly: false,
    }      
  },
  computed: {
    ...mapGetters(['baseUrl', 'clientId', 'jwt', 'user', 'loading']),
    endpoint () {
      return `${this.baseUrl}/v1/users/me/`
    },
    headers () {
      return {
        'Authorization': `Bearer ${this.jwt}`
      }
    },
    config () {
      return {
        'headers': this.headers
      }
    }
  },
  methods: {
    init () {
      const environment = {
        baseUrl: process.env.VUE_APP_API_URL,
        clientId: process.env.VUE_APP_CLIENT_ID,
        appUrl: process.env.VUE_APP_URL,
      }
      this.$store.dispatch('setEnvironment', environment)
      const jwt = window.$cookies.get('jwt')
      if (jwt !== null) {
        this.$store.dispatch('setJWT', jwt)
      }
      this.load()
    },
    load () {
      if (this.jwt !== null) {
        axios.get(this.endpoint, this.config).then(res => {
          this.$store.dispatch('setUser', res.data)
        }).catch(err => {
          if (err.response && err.response.status !== 403) {
            this.$buefy.toast.open({
              duration: 5000,
              message: err.response.data.detail,
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
          this.$store.dispatch('setUser', null)
        }).finally(()=> {
          this.$store.dispatch('setLoading', false)
        })
      } else {
        this.$store.dispatch('setLoading', false)
      }
    },
    filtersUpdated(filters) {
      this.filters = filters
    },
    entityUpdated(entity) {
      if (entity === 'rmas-approved') {
        this.entity = 'rmas'
        this.approvedOnly = true
      } else if (entity === 'rmas-all') {
        this.entity = 'rmas'
        this.approvedOnly = false
      } else {
        this.entity = entity
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>