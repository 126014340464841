<template>
  <div>
    <b-field label="Brands">
      <b-taginput
        v-model="brands"
        :data="filteredBrands"
        autocomplete
        field="name"
        type="is-primary"
        size="is-small"
        :open-on-focus=true
        icon="tag"
        placeholder="Filter by Brand"
        @typing="getFilteredBrands"
        @input="updateBrands">
        <template v-slot="props">
          <strong>{{props.option.id}}</strong>: {{props.option.name}}
        </template>
        <template #empty>
          There are no items
        </template>
      </b-taginput>
    </b-field>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'Brands',
  data() {
    return {
      brands: [],
      filteredBrands: [],
      allBrands: []
    }
  },
  computed: {
    ...mapGetters(['baseUrl', 'jwt', 'user', 'loading']),
    endpoint () {
      return `${this.baseUrl}/v1/summary/brands/?short=1&pagination=0&status=active`
    },
    headers () {
      return {
        'Authorization': `Bearer ${this.jwt}`
      }
    },
    config () {
      return {
        'headers': this.headers
      }
    }
  },
  methods: {
    load () {
      axios.get(this.endpoint, this.config).then(res => {
        this.allBrands = res.data.results
        this.$emit('brands:loaded')
      }).catch((err) => {
        if (err.response && err.response.status === 401) {
          this.$store.dispatch('refreshToken').then(() => {
            this.load()
          }).catch(() => {
            this.$store.dispatch('setUser', null)
          })
        }
      })
    },
    getFilteredBrands(text) {
      this.filteredBrands = this.allBrands.filter(brand => {
        return brand.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
      })
    },
    updateBrands () {
      const brands = this.brands.map(brand => {
        return brand.id.toString()
      }).join(',')
      this.$emit('brands:updated', brands)
    }
  },
  mounted () {
    this.load()
  }
}
</script>