<template>
  <div>
    <b-field label="Retailers">
      <b-taginput
        v-model="retailers"
        :data="filteredRetailers"
        autocomplete
        field="name"
        type="is-primary"
        size="is-small"
        :open-on-focus=true
        icon="tag"
        placeholder="Filter by Retailer"
        @typing="getFilteredRetailers"
        @input="updateRetailers">
        <template v-slot="props">
          <strong>{{props.option.id}}</strong>: {{props.option.name}}
        </template>
        <template #empty>
          There are no retailers
        </template>
      </b-taginput>
    </b-field>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'Retailers',
  data() {
    return {
      retailers: [],
      filteredRetailers: [],
      allRetailers: []
    }
  },
  computed: {
    ...mapGetters(['baseUrl', 'jwt', 'user', 'loading']),
    endpoint () {
      return `${this.baseUrl}/v1/summary/retailers/?mini=1&pagination=0&status=active`
    },
    headers () {
      return {
        'Authorization': `Bearer ${this.jwt}`
      }
    },
    config () {
      return {
        'headers': this.headers
      }
    }
  },
  methods: {
    load () {
      axios.get(this.endpoint, this.config).then(res => {
        this.allRetailers = res.data.results
        this.$emit('retailers:loaded')
      }).catch((err) => {
        if (err.response && err.response.status === 401) {
          this.$store.dispatch('refreshToken').then(() => {
            this.load()
          }).catch(() => {
            this.$store.dispatch('setUser', null)
          })
        }
      })
    },
    getFilteredRetailers(text) {
      this.filteredRetailers = this.allRetailers.filter(retailer => {
        return retailer.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
      })
    },
    updateRetailers () {
      const retailers = this.retailers.map(retailer => {
        return retailer.id.toString()
      }).join(',')
      this.$emit('retailers:updated', retailers)
    }
  },
  mounted () {
    this.load()
  }
}
</script>