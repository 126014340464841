import Vue from 'vue'
import Vuex from 'vuex'
import 'es6-promise/auto'
import App from './App.vue'
import Buefy from 'buefy'
import VueCookies from 'vue-cookies'
import JsonCSV from 'vue-json-csv'

import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import jwt_decode from "jwt-decode"
library.add(fas);

import './assets/css/vendors.scss'
import './assets/css/main.scss'
const axios = require('axios')

Vue.component('vue-fontawesome', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
  customIconPacks: {
    fas: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      iconPrefix: ""
    }
  }
})

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(Vuex)
Vue.use(JsonCSV)
Vue.component('downloadCsv', JsonCSV)

const store = new Vuex.Store({
  state: {
    count: 0,
    user: null,
    loading: true,
    jwt: null,
    accessExpiresAt: null,
    refreshExpiresAt: null,
    baseUrl: null,
    clientId: null,
    appUrl: null
  },
  getters: {
    baseUrl (state) {
      return state.baseUrl
    },
    clientId (state) {
      return state.clientId
    },
    jwt (state) {
      return state.jwt
    },
    user (state) {
      return state.user
    },
    loading (state) {
      return state.loading
    }
  },
  mutations: {
    increment (state) {
      state.count++
    },
    setCredentials(state, jwt) {
      const decoded = jwt_decode(jwt)
      state.accessToken = decoded.access_token
      state.refreshToken = decoded.refresh_token
      state.jwt = jwt
    },
    setUser(state, user) {
      state.user = user
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setEnvironment (state, environment) {
      state.baseUrl = environment.baseUrl
      state.clientId = environment.clientId
      state.appUrl = environment.appUrl
    },
    setJWT(state, jwt) {
      state.jwt = jwt
      axios.get(`${state.baseUrl}/v1/users/me/`, {headers: {Authorization: `Bearer ${state.jwt}`}}).then(res => {
        window.$cookies.set('jwt', jwt, '9h', '/')
        state.user = res.data
      }).catch(err => {
        if (err.response && err.response.status === 401) {
        // if token is expired, and JWT contains refresh token, attempt to refresh it.
          try {
            const decoded = jwt_decode(jwt)
            if (decoded.refresh_token !== undefined) {
              const formData = new FormData()
              formData.set('client_id', state.clientId)
              formData.set('grant_type', 'refresh_token')
              formData.set('jwt', jwt)
              axios.post(`${state.baseUrl}/jwt-auth/token/`, formData).then(res => {
                state.jwt = res.data.jwt
                window.$cookies.set('jwt', state.jwt, '9h', '/')
                axios.get(`${state.baseUrl}/v1/users/me/`, {headers: {Authorization: `Bearer ${state.jwt}`}}).then(res => {
                  state.user = res.data
                }).catch(() => {
                  this.$buefy.toast.open({
                    duration: 5000,
                    message: 'An error occurred retrieving user\'s data, please Log in again.',
                    position: 'is-bottom',
                    type: 'is-danger'
                  })
                })
              }).catch(()=> {
                this.$buefy.toast.open({
                  duration: 5000,
                  message: 'An authentication error occurred, please Log in again.',
                  position: 'is-bottom',
                  type: 'is-danger'
                })
              })
            }
          } catch(e) {
            console.error(e)
          }
        }
      })
    }
  },
  actions: {
    setEnvironment(context, environment) {
      context.commit('setEnvironment', environment)
    },
    setJWT(context, jwt) {
      context.commit('setJWT', jwt)
    },
    setUser(context, user) {
      context.commit('setUser', user)
      if (user === null) {
        window.$cookies.remove('jwt')
      }
    },
    setLoading(context, loading) {
      context.commit('setLoading', loading)
    },
    refreshToken({commit, state}) {
      return new Promise((resolve, reject)=> {
        try {
          const decoded = jwt_decode(state.jwt)
          if (decoded.refresh_token !== undefined) {
            const formData = new FormData()
            formData.set('client_id', state.clientId)
            formData.set('grant_type', 'refresh_token')
            formData.set('jwt', state.jwt)
            axios.post(`${state.baseUrl}/jwt-auth/token/`, formData).then(res => {
              commit('setJWT', res.data.jwt)
              resolve()
            }).catch(()=> {
              this.$buefy.toast.open({
                duration: 5000,
                message: 'An authentication error occurred, please Log in again.',
                position: 'is-bottom',
                type: 'is-danger'
              })
              reject()
            })
          }
        } catch(e) {
          console.error(e)
        }
      })
    }
  }
})

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
