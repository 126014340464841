<template>
  <div class="card">
    <div class="card-content">
      <div class="content">
        <b-tabs v-model="activeTab" type="is-primary">
          <b-tab-item label="Data" icon="table">
            <div style="max-height=522px;height:522px;overflow:auto;">
              <b-table
                :data="tableResults"
                :default-sort="defaultSort"
                :sort-icon="tableProps.sortIcon"
                :bordered="tableProps.bordered"
                :striped="tableProps.striped"
                :narrowed="tableProps.narrowed"
                :hoverable="tableProps.hoverable"
                :loading="loading"
                :sticky-headers="tableProps.stickyHeaders"
              >
                <b-table-column field="label" :label="label" centered sortable v-slot="props">
                  {{ filters.dimension === 'variant' || filters.dimension === 'product' ? `(${props.row.key}) ${props.row.name}, SKU: ${props.row.identifier}` : props.row.label}}
                </b-table-column>
                <b-table-column field="connections" label="Connections" sortable numeric v-slot="props">
                  {{ thousandSeparated(props.row.connections, 0) }}
                </b-table-column>
                <b-table-column field="entity_count" :label="entityLabel" sortable numeric v-slot="props">
                  {{ thousandSeparated(props.row.entity_count, 0) }}
                </b-table-column>
                <b-table-column field="wholesale_cost" label="Cost" sortable numeric v-slot="props">
                  {{ formatCurrency(props.row.wholesale_cost) }}
                </b-table-column>
                <b-table-column field="estimated_gmv" label="GMV" sortable numeric v-slot="props">
                  {{ formatCurrency(props.row.estimated_gmv) }}
                </b-table-column>
                <b-table-column field="estimated_aov" label="AOV" sortable numeric v-slot="props">
                  {{ formatCurrency(props.row.estimated_aov) }}
                </b-table-column>
                <template slot="footer" v-if="results !== null">
                  <td>
                    <strong>Totals: </strong>&nbsp;{{ thousandSeparated(this.totals.count, 0) }}
                  </td>
                  <td class="has-text-right">
                    {{ thousandSeparated(this.totals.connections, 0) }}
                  </td>
                  <td class="has-text-right">
                    {{ thousandSeparated(this.totals.entity_count, 0) }}
                  </td>
                  <td class="has-text-right">
                    {{ formatCurrency(this.totals.wholesale_cost, 2) }}
                  </td>
                  <td class="has-text-right">
                    {{ formatCurrency(this.totals.estimated_gmv, 2) }}
                  </td>
                  <td class="has-text-right">
                    {{ formatCurrency(this.totals.estimated_aov, 2) }}
                  </td>
                </template>
              </b-table>
            </div>
            <div class="columns">
              <div class="column has-text-left">
                <download-csv :data="tableResults" name="export.csv" :labels="fileLabels" :fields="fileFields">
                  <b-button size="is-small" icon-left="download">Export</b-button>
                </download-csv>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Chart" icon="chart-bar">
            <div class="columns">
              <div class="column is-2 has-text-left">
                <b-field label="Metric">
                  <b-select placeholder="Select the metric..." v-model="metric" size="is-small">
                    <option value="entity_count">{{ entityLabel }} Count</option>
                    <option value="estimated_gmv">GMV</option>
                    <option value="estimated_aov">AOV</option>
                    <option value="wholesale_cost">Cost</option>
                    <option value="connections">Connections</option>
                  </b-select>
                </b-field>
              </div>
            </div>
            <div class="columns" v-if="metric !== null">
              <div class="column">
                <report-chart
                :response-data="results"
                :dimension="filters.dimension"
                :metric="metric"
                :loading="loading"
                :height="height"
                :entity="entity"
                ></report-chart>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
const numbro = require('numbro')
const _ = require('lodash')
import ReportChart from './ReportChart.vue'
export default {
  name: 'Report',
  components: {
    ReportChart
  },
  data () {
    return {
      activeTab: 0,
      results: {},
      tableResults: [],
      metric: 'estimated_gmv',
      height: 472,
      loading: true,
      tableProps: {
        bordered: false,
        striped: true,
        narrowed: true,
        hoverable: true,
        sortIcon: "arrow-up",
        stickyHeaders: true
      },
      dimensionLabel: '',
      totals: {
        count: 0,
        connections: 0,
        entity_count: 0,
        wholesale_cost: 0,
        estimated_gmv: 0,
        estimated_aov: 0
      },
      fileFields: ['label', 'connections', 'entity_count', 'wholesale_cost', 'estimated_gmv', 'estimated_aov'],
      entityLabels: {
        orders: 'Orders',
        rmas: 'Returns',
        cancels: 'Cancels'
      },
      invalidValues: [null, undefined, '']
    }
  },
  props: {
    filters: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    entity: {
      type: String,
      required: true,
      default () {
        return 'orders'
      }
    },
    approvedOnly: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  },
  computed: {
    ...mapGetters(['baseUrl', 'clientId', 'jwt', 'user']),
    endpoint () {
      return `${this.baseUrl}/v1/summary/${this.entity}/`
    },
    headers () {
      return {
        'Authorization': `Bearer ${this.jwt}`
      }
    },
    config () {
      return {
        headers: this.headers,
        params: this.filters
      }
    },
    label () {
      return this.filters !== null && this.filters.dimension !== null ? this.capitalize(this.filters.dimension) : ''
    },
    defaultSort () {
      return 'label'
    },
    fileLabels () {
      let entityLabel = ''
      switch (this.entity) {
        case 'orders':
          entityLabel = 'Sales'
          break
        case 'rmas':
          entityLabel = 'Returns'
          break
        case 'cancels':
          entityLabel = 'Cancels'
          break
      }
      return {
        label: this.label,
        connections: 'Connections',
        entity_count: `# ${entityLabel}`,
        wholesale_cost: 'Cost',
        estimated_gmv: 'Estimated GMV',
        estimated_aov: 'Estimated AOV'
      }
    },
    isReady () {
      return this.filters !== null && !this.invalidValues.includes(this.filters.limit)
    },
    entityLabel () {
      return this.entityLabels[this.entity]
    }
  },
  methods: {
    load () {
      if (this.isReady) {
        this.loading = true
        this.tableResults = []
        const entityCount = `${this.entity}_count`
        const summary = {
          count: 0,
          connections: 0,
          entity_count: 0,
          wholesale_cost: 0,
          estimated_gmv: 0,
          estimated_aov: 0
        }
        axios.get(this.endpoint, this.config).then(res => {
          this.results = res.data
          Object.keys(this.results[this.filters.dimension]).forEach(key => {
            const result = this.results[this.filters.dimension][key]
            result.key = key
            result.entity_count = result[entityCount]
            this.tableResults.push(result)
            summary.count++
            summary.connections += result.connections
            summary.entity_count += result[entityCount]
            summary.wholesale_cost += result.wholesale_cost
            summary.estimated_gmv += result.estimated_gmv
            summary.estimated_aov += result.estimated_aov
          })
        }).catch((err) => {
          if (err.response && err.response.status === 401) {
            this.$store.dispatch('refreshToken').then(() => {
              this.load()
            }).catch(() => {
              this.$store.dispatch('setUser', null)
            })
          } else {
            this.$buefy.toast.open({
              duration: 3000,
              message: 'An unexpected error occurred, please try again.',
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        }).finally (() => {
          this.loading = false
          this.totals = Object.assign({}, this.totals, summary)
        })
      } else {
        this.loading = false
      }
    },
    capitalize (text) {
      return text !== undefined ? text.charAt(0).toUpperCase() + text.slice(1) : '';
    },
    thousandSeparated (value, mantissa) {
      return numbro(value).format({thousandSeparated: true, mantissa: mantissa})
    },
    formatCurrency (value) {
      return `$${numbro(value).format({thousandSeparated: true, mantissa: 2})}`
    }
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      handler () {
        this.load()
      }
    }
  },
  mounted () {
    this.load()
  },
  created () {
    this.load = _.debounce(this.load, 1000)
  }
}
</script>