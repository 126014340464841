<template>
  <div>
      <div class="container chart-container">
        <div>
        <b-loading :is-full-page="isFullPage" :active.sync="loading" :can-cancel="canCancel"></b-loading>
        <line-chart
          v-if="dimension==='date'"
          :chart-data="chartData"
          :chart-options="myOptions"
          :styles="chartStyles"
          css-classes="chart-container"
        ></line-chart>
        <bar-chart
          v-else
          :chart-data="chartData"
          :chart-options="chartOptions"
          :styles="chartStyles"
          css-classes="chart-container"
        ></bar-chart>
        </div>
      </div>
  </div>
</template>
<script>
const numbro = require('numbro')
const dayjs = require('dayjs')
const _ = require('lodash')
import LineChart from './LineChart.vue'
import BarChart from './BarChart.vue'
export default {
  name: 'ReportChart',
  components: {
    LineChart,
    BarChart
  },
  data () {
    return {
      canCancel: false,
      isFullPage: false,
      metricsDisplay: {
        entity_count: 'Orders',
        estimated_gmv: 'Estimated GMV ($)',
        estimated_aov: 'Estimated AOV ($)',
        wholesale_cost: 'Wholesale Cost ($)',
        connections: 'Connections'
      },
      myOptions: {},
      entityLabels: {
        'orders': 'Orders',
        'rmas': 'Returns',
        'approved-rmas': 'Approved Returns',
        'all-rmas': 'All Returns',
        'cancels': 'Cancels'
      },
    }
  },
  computed: {
    chartOptions () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scaleShowValues: true,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip:
                  (this.dimension === 'date' ||
                  this.chartData.datasets[0].data.length > 30)
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: this.formatTicks
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label
              let value = ''
              if (
                this.metric === 'connections' ||
                this.metric === 'entity_count'
              ) {
                value = this.thousandSeparated(tooltipItem.yLabel, 0)
              } else {
                value = this.formatCurrency(tooltipItem.yLabel, 2)
              }
              return `${label}: ${value}`
            }
          }
        }
      }
    },
    chartStyles () {
      return {
        position: 'relative',
        height: `${this.height}px`
      }
    },
    chartData () {
      const unordered = this.responseData[this.dimension] || {}
      let results = []
      const data = {
        datasets: [
          {
            label: this.metric !== 'entity_count' ? this.metricsDisplay[this.metric] : this.entityLabels[this.entity],
            backgroundColor: '#1654FB',
            data: []
          }
        ],
        labels: []
      }
      switch (this.dimension) {
        case 'date':
          Object.keys(unordered).sort().forEach((key) => {
            unordered[key].label = key.substring(0, 10)
            data.labels.push(dayjs(unordered[key].label).format('MMM D, YYYY'))
            results.push(unordered[key])
            data.datasets[0].data.push(unordered[key][this.metric])
          })
          break
        case `${this.entity.slice(0, -1)}_status`:
          Object.keys(unordered).sort().forEach((key) => {
            unordered[key].label = key
            data.labels.push(key)
            results.push(unordered[key])
            data.datasets[0].data.push(unordered[key][this.metric])
          })
          break
        case 'brand':
          results = _.orderBy(unordered, 'wholesale_cost', 'desc')
          results.forEach((item) => {
            item.label = item.name
            data.datasets[0].data.push(item[this.metric])
            data.labels.push(item.label)
          })
          break
        default:
          results = _.orderBy(unordered, 'estimated_gmv', 'desc')
          results.forEach((item) => {
            item.label =
              this.dimension === 'variant' || this.dimension === 'product' ? item.identifier : item.name
            data.datasets[0].data.push(item[this.metric])
            data.labels.push(item.label)
          })
          break
      }
      return data
    }
  },
  props: {
    responseData: {
      type: Object
    },
    metric: {
      type: String
    },
    dimension: {
      type: String,
      default: 'date'
    },
    loading: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 300
    },
    entity: {
      type: String,
      required: true,
      default: 'orders'
    },
    entityLabel () {
      return this.entityLabels[this.entity]
    }
  },
  methods: {
    thousandSeparated (value, mantissa) {
      return numbro(value).format({thousandSeparated: true, mantissa: mantissa})
    },
    formatCurrency (value) {
      return `$${numbro(value).format({thousandSeparated: true, mantissa: 2})}`
    },
    formatTicks (value) {
      if (
        this.metric === 'connections' || this.metric === 'entity_count') {
        return this.thousandSeparated(value, 0)
      } else {
        return this.formatCurrency(value, 2)
      }
    },
    updateOptions () {
      this.myOptions = Object.assign({}, this.myOptions, {
        responsive: true,
        maintainAspectRatio: false,
        scaleShowValues: true,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip:
                  (this.dimension === 'date' ||
                  this.chartData.datasets[0].data.length > 30)
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: this.formatTicks
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label
              let value = ''
              if (
                this.metric === 'connections' ||
                this.metric === 'entity_count'
              ) {
                value = this.thousandSeparated(tooltipItem.yLabel, 0)
              } else {
                value = this.formatCurrency(tooltipItem.yLabel, 2)
              }
              return `${label}: ${value}`
            }
          }
        }
      })
    }
  },
  watch: {
    responseData : {
      deep: true,
      immediate: true,
      handler () {
        this.updateOptions()
      }
    },
    metric: {
      immediate: true,
      handler () {
        this.updateOptions ()
      }
    }
  },
  mounted () {

  }
}
</script>
<style scoped>
  .chart-container {
    flex-grow: 1;
    min-height: 0px;
    max-height: 472px;
    width: "100%";
    position: "relative";
  }
</style>
