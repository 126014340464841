<template>
  <section>
    <b-modal
      has-modal-card
      scroll="keep"
      :active="showLogin"
      :can-cancel="false"
    >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title"><span><Fabric color="black" :width="80" :height="26" /></span></p>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="login" novalidate>

          <b-field label="Email" horizontal>
            <b-input v-model="email" type="email" placeholder="Please enter your email"></b-input>
          </b-field>
          <b-field label="Password" horizontal>
            <b-input v-model="password" type="password" password-reveal></b-input>
          </b-field>
          <b-field class="has-text-right">
            <b-checkbox v-model="rememberMe">Remember me.</b-checkbox>
          </b-field>
          <div class="buttons is-centered">
            <b-button type="is-primary" outlined @click="login" :disabled="!isReady" native-type="submit">Login</b-button>
            <b-button type="is-danger" outlined @click="close">Cancel</b-button>
          </div>
        </form>
      </section>
    </div>
    </b-modal>
  </section>
</template>
<script>
  import Fabric from './Fabric.vue'
  import { mapGetters } from 'vuex'
  const axios = require('axios')
  const emailRegex = /^(([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+)?$/g
  export default {
    name: 'Login',
    props: {
      showLogin: {
        type: Boolean,
        required: true,
        default: true
      }
    },
    components: {
      Fabric
    },
    computed: {
      ...mapGetters(['baseUrl', 'clientId', 'user']),
      endpoint () {
        return `${this.baseUrl}/jwt-auth/token/`
      },
      payload () {
        const formData = new FormData()
        formData.set('grant_type', 'password')
        formData.set('client_id', this.clientId)
        formData.set('username', this.email)
        formData.set('password', this.password)
        formData.set('remember_me', this.rememberMe ? '1' : '0')
        return formData
      },
      emailIsValid () {
        return this.email !== null && this.email.match(emailRegex)
      },
      isReady () {
        return this.emailIsValid && this.password !== null && this.password !== ''
      }
    },
    data() {
      return {
        email: null,
        password: null,
        rememberMe: true,
        message: null,
      }
    },
    methods: {
      close() {
        this.$emit('login:canceled')
      },
      login () {
        axios.post(this.endpoint, this.payload).then(res => {
          this.$store.dispatch('setJWT', res.data.jwt)
          this.close()
        }).catch(err => {
          let errorMessage = 'An unexpected error occured!'
          if (err.response && err.response.data.detail !== undefined) {
            errorMessage = err.response.data.detail
          }
          this.$buefy.toast.open({
            duration: 5000,
            message: errorMessage,
            position: 'is-bottom',
            type: 'is-danger'
          })
          this.password = null
        })
      }
    }
  }
</script>