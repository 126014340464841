<template>
  <b-navbar :spaced=false :shadow="true" :fixed-top=true>
    <template #brand>
    </template>
    <template #start>
      <b-navbar-item href="#">
        <Fabric color="white" :width="80" :height="26" />
      </b-navbar-item>
      <b-navbar-item href="#" @click="updateEntity('orders')">
        Orders
      </b-navbar-item>
      <b-navbar-item href="#" @click="updateEntity('cancels')">
        Cancels
      </b-navbar-item>
      <b-navbar-dropdown label="Returns">
        <b-navbar-item href="#" @click="updateEntity('rmas-approved')">
          Approved
        </b-navbar-item>
        <b-navbar-item href="#" @click="updateEntity('rmas-all')">
          All
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <a v-if="user === null" class="button is-primary" @click="$emit('login')">
            Log in
          </a>
          <a v-if="user !== null" class="button is-info" @click="logout">
            Log out
          </a>
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>
<script>
import { mapState } from 'vuex'
import Fabric from './Fabric.vue'
export default {
  name: 'Navbar',
  data () {
    return {}
  },
  components: {
    Fabric
  },
  computed: mapState(['count', 'user']),
  methods: {
    login () {
      this.$store.dispatch('setUser', null)
      this.$emit('login')
    },
    logout () {
      this.$store.dispatch('setUser', null)
    },
    updateEntity(entity) {
      this.$emit('entity:updated', entity)
    }
  }
}
</script>