<template>
  <div class="columns">
      <section class="column has-text-left">
        <div>
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <b-field label="Start Date">
                <b-datepicker
                  placeholder="Click to select..."
                  size="is-small"
                  v-model="startDate"
                  @input="filtersUpdated">
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <b-field label="End Date">
                <b-datepicker
                  placeholder="Click to select..."
                  size="is-small"
                  v-model="endDate"
                  @input="filtersUpdated">
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <b-field label="Dimension">
                <b-select placeholder="Select a dimension..." v-model="dimension" size="is-small" @input="filtersUpdated">
                  <option value="date">Date</option>
                  <option value="brand">Brand</option>
                  <option value="retailer">Retailer</option>
                  <option value="variant">Variant</option>
                  <option value="product">Product</option>
                  <option :value="entity.slice(0, -1) + '_status'">Status</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div v-if="dimension === 'date'" class="columns">
            <div class="column is-10 is-offset-1">
              <b-field label="Calendar interval">
                <b-select placeholder="Select an interval..." v-model="interval" size="is-small" @input="filtersUpdated">
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="quarter">Quarter</option>
                  <option value="year">Year</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div v-if="dimension === 'date' && interval === 'week'" class="columns">
            <div class="column is-10 is-offset-1">
              <b-field label="First day of week">
                <b-select placeholder="Select a weekday." v-model="weekDayOffset" size="is-small" @input="filtersUpdated">
                  <option value="-1d">Sunday</option>
                  <option value="0d">Monday</option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <brands v-model="brands" @brands:updated="brandsUpdated" @brands:loaded="loadingSteps"></brands>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <retailers v-model="retailers" @retailers:updated="retailersUpdated" @retailers:loaded="loadingSteps"></retailers>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <variants v-model="variants" @variants:updated="variantsUpdated"></variants>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <products v-model="products" @products:updated="productsUpdated"></products>
            </div>
          </div>
          <div v-if="entity==='orders'">
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <b-field>
                  <b-switch v-model="openOrders" size="is-small" type="is-primary" @input="filtersUpdated">Open orders</b-switch>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <b-field>
                  <b-switch v-model="partialOrders" size="is-small" type="is-primary" @input="filtersUpdated">Partial orders</b-switch>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <b-field>
                  <b-switch v-model="closedOrders" size="is-small" type="is-primary" @input="filtersUpdated">Closed orders</b-switch>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <b-field>
                  <b-switch v-model="canceledOrders" size="is-small" type="is-primary" @input="filtersUpdated">Fully canceled orders</b-switch>
                </b-field>
              </div>
            </div>
          </div>
          <div v-else-if="entity === 'rmas' && !approvedOnly">
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <b-field>
                  <b-switch v-model="pendingRMAs" size="is-small" type="is-primary" @input="filtersUpdated">Pending Returns</b-switch>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <b-field>
                  <b-switch v-model="approvedRMAs" size="is-small" type="is-primary" @input="filtersUpdated">Approved Returns</b-switch>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-10 is-offset-1">
                <b-field>
                  <b-switch v-model="rejectedRMAs" size="is-small" type="is-primary" @input="filtersUpdated">Rejected Returns</b-switch>
                </b-field>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-10 is-offset-1">
              <b-field label="Bucket limit">
                <b-numberinput
                  type="is-primary"
                  v-model="limit"
                  size="is-small"
                  controls-position="compact"
                  controls-rounded
                  @input="filtersUpdated">
                </b-numberinput>
              </b-field>
            </div>
          </div>
          <br>
        </div>
      </section>
  </div>
</template>
<script>
const dayjs = require('dayjs')
import Brands from  './Brands.vue'
import Retailers from './Retailers.vue'
import Variants from './Variants.vue'
import Products from './Products.vue'
export default {
  name: 'Sidebar',
  components: {
    Brands,
    Retailers,
    Variants,
    Products
  },
  data() {
    return {
      isOpen: true,
      startDate: new Date(new Date().setDate(new Date().getDate()-30)),
      endDate: new Date(),
      dimension: 'retailer',
      interval: 'day',
      weekDayOffset: '-1d',
      brands: null,
      retailers: null,
      variants: null,
      products: null,
      openOrders: true,
      partialOrders: true,
      closedOrders: true,
      canceledOrders: false,
      pendingRMAs: false,
      approvedRMAs: true,
      rejectedRMAs: false,
      limit: 30,
      loadSteps: 0
    }
  },
  props: {
    entity: {
      type: String,
      required: true,
      default: 'orders'
    },
    approvedOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    status () {
      const s = Array()
      switch (this.entity) {
        case 'orders':
          if (this.openOrders) {
            s.push('open')
          }
          if (this.partialOrders) {
            s.push('partial')
          }
          if (this.closedOrders) {
            s.push('closed')
          }
          if (this.canceledOrders) {
            s.push('canceled')
          }
          break

        case 'rmas': {
          if (this.pendingRMAs) {
            s.push('pending')
          }
          if (this.approvedRMAs) {
            s.push('approved')
          }
          if (this.rejectedRMAs) {
            s.push('rejected')
          }
          break
        }
      }
      return s.length > 0 ? s.join(',') : null
    },
    filters (){
      const f = {
        dimension: this.dimension,
        limit: this.dimension === 'date'
          ? dayjs(this.endDate).diff(dayjs(this.startDate), 'day')
          : this.limit
      }
      if (this.dimension === 'date') {
        f.interval = this.interval
      }
      if (this.startDate !== null) {
        f.start_date = dayjs(this.startDate).format('YYYY-MM-DD')
      }
      if (this.endDate !== null) {
        f.end_date = dayjs(this.endDate).format('YYYY-MM-DD')
      }
      ['brands', 'retailers', 'variants', 'products'].forEach(field => {
        if(this[field] !== null) {
          f[field] = this[field]
        }
      })
      if (this.status !== null) {
        f[`${this.entity.slice(0, -1)}_status`] = this.status
      }
      if (this.dimension === 'date' && this.interval === 'week') {
        f.week_day_offset = this.weekDayOffset
      }
      f.approved_only = (this.entity === 'rmas' && this.approvedOnly) ? 1 : 0
      return f
    }
  },
  methods: {
    brandsUpdated(brands) {
      this.brands = brands.length > 0 ? brands : null
      this.filtersUpdated ()

    },
    retailersUpdated(retailers) {
      this.retailers = retailers.length > 0 ? retailers : null
      this.filtersUpdated ()

    },
    variantsUpdated(variants) {
      this.variants = variants.length > 0 ? variants : null
      this.filtersUpdated ()
    },
    productsUpdated(products) {
      this.products = products.length > 0 ? products : null
      this.filtersUpdated ()
    },
    filtersUpdated () {
      this.$emit('filters:updated', this.filters)
    },
    loadingSteps () {
      this.loadSteps += 1
      if (this.loadSteps === 2) {
        this.$emit('filters:updated', this.filters)
        this.$store.dispatch('setLoading', false)

      }
    }
  },
  mounted () {
    this.$store.dispatch('setLoading', true)
  },
  watch: {
    entity() {
      this.dimension = 'retailer'
      this.$emit('filters:updated', this.filters)
      this.$store.dispatch('setLoading', false)
    },
    approvedOnly() {
      this.$emit('filters:updated', this.filters)
    },
  }
}
</script>
